import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { map, catchError, take, filter, switchMap, delay } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalSesionExpiradaComponent } from '../pages/account/modal-sesion-expirada/modal-sesion-expirada.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Store } from '@ngrx/store';
import { AppState } from '../@store/app.reducers';
import { EncDecService } from './auth/enc-dec.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    @BlockUI() blockUI: NgBlockUI;
    private nuevoToken = new BehaviorSubject<any>(null);
    private esperandoToken = false;
    private usuario: string;
    private urlPagosMB = environment.multipagosBBVAUrl;

    private encriptar = [
        'POST::api/account/tarjetas',
        'POST::api/account/insertaUsuario',
        'POST::api/centropagos/pagotarjetasiniestros',
        'POST::api/centropagos/pagar',
        'POST::api/contratantes',
        'POST::api/contratantes/direccion',
        'POST::api/contratantes/busqueda',
    ];
    private desencriptar = [
        'GET::api/account/cargaDatosGeneralesAgente',
        'POST::api/contratantes/direccion',
        'GET::api/contratantes/asegurado', //'GET::api/contratantes/{{IDCONTRATANTE}}'
        'GET::api/contratantes/direccion/asegurado', //'GET::api/contratantes/direccion/{{IDCONTRATANTE}}'
        'POST::api/contratantes/busqueda',
    ];

    private pathWsSinToken = [
        '/fcm/send',
        'api/account/login',
        'token/refresh',
        '/unirdescargapaperless',
        '/descargapaperless',
        'Cristales',
        'mensaje/envioSMS',
        'consultaPolizaCristales',
        // 'consultaReciboPorPagar',
        'obtieneDatosUrlPagoEnLinea',
        'obtenerDatosPago'
    ];

    private dominiosSinToken = [
        'https://ies-autoajustador.com.mx/',
        'https://prometeo.segurosatlas.com.mx/',
        this.urlPagosMB
    ];

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private encDecService: EncDecService,
        private store: Store<AppState>) {
            this.store.select('usuario').pipe(
            filter((r) => r.nombreUsuario !== null)
            ).subscribe(res => {
            this.usuario = res.nombreUsuario;
            });
    }

    agregarToken(request) {
        const token = localStorage.getItem('AP-TK');
        if (token && !request.url.includes('/fcm/send') 
        && this.pathWsSinToken.every(url => !request.url.includes(url))
        && this.dominiosSinToken.every(dominio => !request.url.includes(dominio)) ) {
            return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }
        return request;
    }

    validarEncDec( urlMin: string, metodo: string, arr: string[] ) : boolean { 
        if (environment.encripcion === false) return false;
        if (!urlMin || !metodo || !arr?.length ) return false;
        let valRapida = urlMin.includes('api/account') || urlMin.includes('api/contratantes') || urlMin.includes('api/centropagos');
        if (!valRapida) return false;
        // if (environment.name === 'Local') console.log(`${metodo}::${urlMin}`);
        let idPro = urlMin.replace('api/contratantes/', '').replace('direccion/', '');
        let valCompleta =  arr.includes(`${metodo}::${urlMin}`);
        if (!valCompleta && !idPro.includes('/')) valCompleta = arr.includes(`${metodo}::${urlMin.replace(idPro, '')}`)
        // if (environment.name === 'Local') console.log(valCompleta, idPro);
        return valCompleta;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let rta : string;
        const urlMin = request.url.replace( environment.wsUrl, '');
        let encriptar = this.validarEncDec(urlMin, request.method, this.encriptar);
        let desencriptar = this.validarEncDec(urlMin, request.method, this.desencriptar);
        // console.log(encriptar, desencriptar);

        request = request.clone({ headers: request.headers.set('Cache-Control', 'no-cache') });
        request = request.clone({ headers: request.headers.set('Pragma', 'no-cache') });
        request = request.clone({ headers: request.headers.set('Expires', '0') });
        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        // if (request.url.includes("api/account/")) this.blockUI.start();
        // if (request.url.includes("api/account/")) console.log(request.url);
        
        let preConsulta = encriptar || desencriptar ? this.store.select('usuario').pipe(take(1)) : of( { rta: null } ).pipe(delay(100));

        return preConsulta.pipe(
            // Condicion parar encriptar
            switchMap(rdx => {
                if (!rdx.rta) return next.handle(this.agregarToken(request));
                rta = rdx.rta.substr(0,16);
                if (!encriptar) return next.handle(this.agregarToken(request));
                let payload = this.encDecService.encriptarAES(JSON.stringify(request.body), rta);
                return next.handle(this.agregarToken( request.clone({ body: { Payload: payload } }) )); 
            }),
             // Respuesta
            map((event: HttpEvent<any>) => {
                // if (request.url.includes("api/account/")) this.blockUI.stop();
                // if (request.url.includes("api/account/")) console.log(request.url);
                if (event instanceof HttpResponse) {
                    if (request.url.includes('api/account/login') || request.url.includes('token/refresh') || request.url.includes('account/cambiocuenta')) {
                        localStorage.setItem('AP-TK', event.body['access_token']);
                        localStorage.setItem('AP-DP', event.body['path_default']);
                        localStorage.setItem('AP-RTK', event.body['refresh_token']);
                    }

                    if (request.url.includes('/validaacceso')) {
                        localStorage.setItem('DATA-PORT', event.body.datos['llave']);
                    }
                    if (desencriptar && (event.body ? event.body.Payload : false) ) return new HttpResponse({
                        ...event,
                        body:  this.encDecService.desencriptarAES(event.body.Payload, rta)
                    })
                }
                if(localStorage.getItem('AP-TK')!=null)
                    window.parent.postMessage(localStorage.getItem('AP-TK'), '*');
                return event;
            }),
            catchError((error: HttpErrorResponse, ) => {
                // console.error(error);
                // Error del login o del refresh automaticamente cierra sesión
                // if (request.url.includes("api/account/")) this.blockUI.stop();
                // console.log('HC httpConfig.interceptor.ts.intercept.catchError', error);
                // if (request.url.includes("token/refresh") || request.url.includes("api/account/login")) {
                if (request.url.includes("token/refresh")) {
                    this.authService.cerrarSesion(true, this.usuario, true);
                    this.blockUI.stop();
                }
                // Error de autentificiacion
                console.log(error.status);

                if (error.status === 401) {
                    if (this.esperandoToken) {
                        return this.nuevoToken.pipe(
                            filter(result => result !== null),
                            take(1),
                            switchMap(() => next.handle(this.agregarToken(request)))
                        );
                    } else {
                        this.esperandoToken = true;
                        this.nuevoToken.next(null);
                        // Lanzar modal para preguntar si desea continuar con la sesion abierta
                        const configModal: MatDialogConfig<any> = {
                            data: null
                        }
                        this.blockUI.stop();
                        const dialogRef = this.dialog.open(ModalSesionExpiradaComponent, configModal).afterClosed().subscribe(resultado => {
                            if (resultado) {
                                this.blockUI.start();
                                this.authService.refrescarToken().pipe(
                                    switchMap((cambio: any) => {
                                        this.esperandoToken = false;
                                        localStorage.setItem('AP-TK', cambio['access_token']);
                                        this.nuevoToken.next(cambio['access_token']);
                                        setTimeout(() => {
                                            this.blockUI.stop();
                                        }, 1000);
                                        location.reload(); // add line 
                                        return next.handle(this.agregarToken(request));
                                    }), catchError(err => {
                                        this.esperandoToken = false;
                                        this.blockUI.stop();
                                        this.authService.cerrarSesion(true);
                                        return throwError(error);
                                    })).subscribe(res => res);
                            } else {
                                this.blockUI.stop();
                                this.authService.cerrarSesion(true, null, true);
                            }
                        });
                    }
                }
                //Multiples Peticiones
                if(/api\/tramites\/.*\/registro\-complemento$/g.test(request.url)){
                    return throwError(error);    
                }

                this.blockUI.stop();
                return throwError(error);
            })
        );

    }

   
}
