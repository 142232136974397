<block-ui [delayStart]="0" [delayStop]="0" [template]="blockTemplate" [class]="template">
    <div class="authentication-wrapper authentication-3">
        <div class="authentication-inner">
            <!-- Side container -->
            <!-- Do not display the container on extra small, small and medium screens -->
            <div class="d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5"
                [style.backgroundImage]="'url('+ imagen +')'" style="background-size: cover;">
                <div class="dejarVer">
                </div>
                <div class="ui-bg-overlay bg-dark opacity-50"></div>
                <!-- Text -->
                <div class="w-100 text-white px-5 container-text">
                    <h1 class="bienvenidoTitulo">BIENVENIDO A<br>ATLAS CONMIGO</h1>
                    <a class="bienvenidoCuerpo">
                        Un portal diseñado para que nuestros clientes y socios de negocios hagan uso de nuestros servicios
                        en línea.
                    </a>
                </div>
    
                <!-- /.Text -->
            </div>
            <div class="d-flex col-lg-4 align-items-center bg-white p-5">
                <!-- Inner container -->
                <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
                <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
                    <div class="w-100">
    
                        <!-- Logo -->
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="ui-w-60">
                                <div class="w-100 position-relative">
                                    <img src="assets/img/icono-atlas.png" height="60px">
                                </div>
                            </div>
                        </div>
                        <!-- / Logo -->
                        <h5 class="slogan">Atlas Conmigo</h5>
                        <h6 class="slogan">Empleado</h6>
                        <div>
                            <form class="my-4" [formGroup]="todoFormulario">
                                <div class="row small">
                                    <mat-form-field class="col-md-6 mt-4">
                                        <mat-label>Número empleado</mat-label>
                                        <input type="tel" matInput formControlName="NumeroEmpleado">
                                    </mat-form-field>
                                    <mat-form-field class="col-md-6 mt-4">
                                        <mat-label>Usuario</mat-label>
                                        <input type="text" minlength="4" maxlength="8" matInput formControlName="Usuario">
                                        <button mat-icon-button type="button" matSuffix >
                                            <mat-icon *ngIf="usuarioValido.is && !usuarioValido.pending" class="check-valido">check_circle</mat-icon>
                                            <mat-icon *ngIf="usuarioValido.pending" class="fa-spin">sync</mat-icon>
                                            <mat-icon *ngIf="!usuarioValido.is && !usuarioValido.pending">error_outline</mat-icon>
                                        </button>
                                        <mat-error>{{obtenerErrores('Usuario')}}</mat-error>
                                        <mat-error *ngIf="todoFormulario.get('Usuario').hasError('minlength')">El nombre de usuario debe tener al menos 4 caracteres.</mat-error>
                                        <mat-hint *ngIf="(!usuarioValido.is && !usuarioValido.pending) && obtenerErrores('Usuario') == ''"  class="errors" >La clave de usuario ya existe.</mat-hint>
                                    </mat-form-field>
                                    <mat-form-field class="col-md-6 mt-4 my-auto" [ngClass]="generaErrorPasswordForm ? 'mat-focused' : ''">
                                        <mat-label>Contraseña</mat-label>
                                        <input matInput #password formControlName="Password" 
                                                [type]="hide ? 'password' : 'text'">
                                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <button mat-icon-button type="button" matSuffix>
                                            <mat-icon id="iconPass" class="mt-1" [ngClass]="{'d-none': validandoPassword }">check_circle</mat-icon>
                                            <mat-icon class="fa-spin" *ngIf="validandoPassword">sync</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-form-field class="col-md-6 mt-4">
                                        <mat-label>Confirma Contraseña</mat-label>
                                        <input matInput formControlName="ConfirmaPassword"
                                            [type]="hide ? 'password' : 'text'">
                                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="todoFormulario.get('ConfirmaPassword').hasError('required')">
                                            El campo es obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="todoFormulario.get('ConfirmaPassword').hasError('match')">
                                            Las contraseñas no coinciden
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="row small">
                                <mat-form-field class="col-md-6 mt-4">
                                    <mat-label>Alias o sobrenombre</mat-label>
                                    <input type="text" matInput
                                        formControlName="Alias">
                                </mat-form-field>
                            </div>
                                <div class="row small">
                                    <mat-form-field class="col-md-12 mt-4">
                                        <mat-label>Teléfono</mat-label>
                                        <span matPrefix>+ &nbsp;</span>
                                        <input type="text" [OnlyNumber]="true" matInput
                                            formControlName="Telefono" maxlength="12">
                                        <mat-icon matSuffix>mode_edit</mat-icon>
                                        <mat-error
                                            *ngIf="todoFormulario.get('Telefono').hasError('required')">
                                            Ingrese un telefono
                                        </mat-error>
                                        <mat-error
                                            *ngIf="todoFormulario.get('Telefono').hasError('minlength')">
                                            Se requieren almenos 12 digitos, iniciando
                                            con el codigo del país 52(México)
                                        </mat-error>
                                    </mat-form-field>
                                    </div>
                                <div class="row small">
                                    <mat-form-field class="col-md-12 mt-4">
                                        <mat-label>Email</mat-label>
                                        <input type="text" matInput formControlName="Email">
                                        <mat-error *ngIf="todoFormulario.get('Email').hasError('required')">
                                            El campo es obligatorio
                                        </mat-error>
                                        <mat-error
                                            *ngIf="todoFormulario.get('Email').hasError('email') || todoFormulario.get('Email').hasError('pattern')">
                                            El campo email debe tener un formato valido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="row small">
                                    <mat-form-field class="col-md-12 mt-4">
                                        <mat-label>Pregunta Secreta</mat-label>
                                        <mat-select formControlName="IdPregunta">
                                            <mat-option *ngFor="let preguntas of catPreguntas" [value]="preguntas.Value">
                                                {{preguntas.Text}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-md-12 mt-4">
                                        <mat-label>Respuesta</mat-label>
                                        <input type="text" matInput formControlName="Respuesta">
                                    </mat-form-field>
                                </div>
    
                                <ng-container>
                                    <div class="row small">
                                        <mat-form-field class="col-md-12">
                                            <mat-label>Nombre</mat-label>
                                            <input type="text" matInput formControlName="Nombre">
                                        </mat-form-field>
                                    </div>
                                    <div class="row small">
                                        <mat-form-field class="col-md-6">
                                            <mat-label>Apellido Paterno</mat-label>
                                            <input type="text" matInput formControlName="Paterno">
                                            <mat-error *ngIf="todoFormulario.get('Paterno').hasError('required')">
                                                El campo es obligatorio
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="col-md-6">
                                            <mat-label>Apellido Materno</mat-label>
                                            <input type="text" matInput formControlName="Materno">
                                            <mat-error *ngIf="todoFormulario.get('Materno').hasError('required')">
                                                El campo es obligatorio
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
    
                                <div class="row small">
                                    <mat-form-field class="col-md-12 mt-4">
                                        <mat-label>RFC</mat-label>
                                        <input type="text" matInput formControlName="RFC" maxlength="13">
                                        <mat-error *ngIf="todoFormulario.get('RFC').hasError('required')">
                                            El RFC es un campo obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="todoFormulario.get('RFC').hasError('pattern')">
                                            El RFC debe tener un formato valido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
    
                                <div class="d-flex justify-content-between align-items-center m-0 my-4">
                                    <button mat-raised-button color="primary" (click)="registrar()"
                                        [disabled]="!todoFormulario.valid || !usuarioValido.is">
                                        <span *ngIf="!loading">REGISTRARSE</span>
                                        <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i> Cargando...</span>
                                    </button>
    
                                    <button class="m-2" mat-raised-button color="primary"
                                        (click)="regresar()">Regresar</button>
    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </block-ui>