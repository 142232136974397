import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'longitudTxt',
  pure: false
})
export class LongitudTxtPipe implements PipeTransform {
  transform(txt: string, ancho?: number): string {
    const cadena = txt.slice(0, ancho ?? txt.length);
    return `${cadena}...`;
  }
}