import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificacionesPushService } from './notificaciones-push.service';
import { EncDecService } from './enc-dec.service';
import { IResponseRestablecerPassword } from 'src/app/models/account/account.model';
import { ORIGEN_APP } from 'src/app/models/pagos/pagos.model';
import { SistemaOperativoService } from '../sistema-operativo/sistema-operativo.service';

const url = environment.wsUrl + 'api/account/';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public soService = inject(SistemaOperativoService);

  constructor(
    private http: HttpClient,
    private router: Router,
    private encDecService: EncDecService, 
    private notiPushService: NotificacionesPushService) { }

  realizarEncriptacion( ws: string, request: any ) : Observable<any> {    
    if (environment.encripcion === false) return this.http.post( ws, JSON.stringify(request));
    return  this.http.get(url + 'obtenerLlavePublica').pipe(
      switchMap(rsp => {
        const dia = new Date().getTime();
        const dikey = Number(String(dia).slice(0,8)) * dia * Math.random();
        let Secret = this.encDecService.encriptarRSA(String(dikey).slice(0,16), rsp["llavePem"].join(""));
        let Payload = this.encDecService.encriptarAES(JSON.stringify(request), String(dikey).slice(0,16));
        // return of(null)
        // let payload = this.encDecService.encriptarRSA(JSON.stringify(request), rsp["llavePem"].join(""))
        return this.http.post( ws, { Payload, Secret });
      }),
    );
  }

  iniciarSesion(info): Observable<any> {
    return this.realizarEncriptacion(`${url}login`, info).pipe(map((data: any) => {
      this.router.navigate([data.path_default]);
      return data;
    }));
    // return this.http.post(url + 'login', JSON.stringify(info)).pipe(
    //   map((data: any) => {
    //     this.router.navigate([data.path_default]);
    //     return data;
    // }));
  }

  generaTokenIncial() : Observable<any> {
    return this.soService.getInformacionDevice().platform == 'mobile' ? of("123sddsfewr") :  this.notiPushService.generarTokenFirebase();
  }

  iniciarSesionExterna(info): Observable<any> {
    return this.realizarEncriptacion(`${url}login`, info).pipe(map((data: any) => {
      localStorage.setItem('USUARIO-ANONYMOUS', "ANONYMOUS");
      return data;
    }));
  }

  iniciaSesionParalela(info): Observable<any> {
    const url = environment.name === 'Produccion' ? 'https://hyperion.segurosatlas.com.mx/WebAppPortales/api/cuentas/validaacceso' : 'https://neutron.segurosatlas.com.mx/WebAppPortales-beta/api/cuentas/validaacceso';
    return this.http.post(url,
      `{
      "usuario": "${info.Usuario}",
      "password": "${info.Password}"
    }`).pipe(tap(data => console.log(data)),
        map((data: any) => {
          return data;
        }));
  }

  cambiarCuentaPortalClasico(cuenta) {
    const url = environment.name === 'Produccion' ? 'https://hyperion.segurosatlas.com.mx/WebAppPortales/api/cuentas/cambiocuenta' : 'https://neutron.segurosatlas.com.mx/WebAppPortales-beta/api/cuentas/cambiocuenta';
    return this.http.post(url,
      `{
        "data": "${localStorage.getItem('DATA-PORT')}",
        "cuenta": "${cuenta.Cuenta}"
      }`).pipe(tap(data => console.log(data)),
        map((data: any) => {
          localStorage.setItem('DATA-PORT', data.datos);
          return data;
        }));
  }  

  cerrarSesion(error?: boolean, usuario?: string, cerrarSesionPush? :boolean, origen ? : ORIGEN_APP) {
    this.cerrarSesionPortalHyperion().subscribe(console.log)

    if (usuario) this.actualizaCreaSesion(usuario, 0).subscribe((_) => { });

    localStorage.removeItem('AP-TK');
    localStorage.removeItem('AP-RTK');
    localStorage.removeItem('AP-STK');
    localStorage.removeItem('AP-SRTK');
    localStorage.removeItem('AP-SDP');
    localStorage.removeItem('ORIGEN-ACCESO-APP-CONTROLAGENTES');
    localStorage.removeItem('AP-DP');
    localStorage.removeItem('DATA-PORT');
    localStorage.removeItem('USUARIO-ANONYMOUS');
    if (error) {
      sessionStorage.setItem('adios', 'salir');
    }
    // Cerrar sesion notificaciones-push
    if(cerrarSesionPush) this.notiPushService.revocarToken().subscribe();
    if (!origen) this.router.navigate(['login']);
  }

  refrescarToken() {
    const info = { token: localStorage.getItem('AP-TK'), refresh: localStorage.getItem('AP-RTK') };
    // const info = { token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJkZW1vNSIsImVtYWlsIjoib21hci5wZXJlekBzZWd1cm9zYXRsYXMuY29tLm14IiwidW5pcXVlX25hbWUiOiIiLCJyb2xlIjoiQURNIiwiYWxpYXMiOiJFamVjdXRpdm8iLCJjdWVudGEiOiIxMTkiLCJ0aXBvQ3VlbnRhIjoiNiIsInBlcmZpbCI6IjAiLCJ0aXBvUGVyZmlsIjoiMCIsIm5iZiI6MTU3MjAyNDAyNiwiZXhwIjoxNTcyMDMxMjI2LCJpYXQiOjE1NzIwMjQwMjYsImlzcyI6IlNlcnZpY2VzQXRsYXNDb25taWdvIiwiYXVkIjoiQXRsYXNDb25taWdvIn0.e-q47sKzyNtrFEjDky4aU4e9pUvxYFJRzkIwXwOHplg', 
    //               refresh: localStorage.getItem('AP-RTK') };
    return this.http.post(url + 'token/refresh', JSON.stringify(info));
  }

  obtenerAlias(usuario: string) {
    return this.http.post(url + 'alias', `{
      "Usuario" : "${usuario}" }`);
  }

  cerrarSesionPortalHyperion() {
    let data = localStorage.getItem('DATA-PORT');
    if (!data || data == undefined || data == 'undefined') return of([]);

    let req = { data };
    return this.http.post(environment.wsUrlHyperion + 'cerrarsesion', req);
  }

  registroUsuario(data) {
    return this.realizarEncriptacion(`${url}registraaseguradoseg`, data);
    // return this.http.post(url + 'registraasegurado', data);
  }
  registrarAgente(data) {
    return this.realizarEncriptacion(`${url}registrausuario`, data);
    // return this.http.post(url + 'registrausuario', data);
  }

  registrarProveedor(data) {
    return this.realizarEncriptacion(`${url}registraproveedor`, data);
    // return this.http.post(url + 'registrausuario', data);
  }

  registrarPromotor(data) {
    return this.realizarEncriptacion(`${url}registrapromotor`, data);
    // return this.http.post(url + 'registrausuario', data);
  }
  registrarReasegurador(data) {
    return this.realizarEncriptacion(`${url}registrareasegurador`, data);
    // return this.http.post(url + 'registrausuario', data);
  }
  registrarEmpleado(data) {
    return this.realizarEncriptacion(`${url}registrarempleado`, data);
    // return this.http.post(url + 'registrausuario', data);
  }
  registrarEjecutivo(data) {
    return this.realizarEncriptacion(`${url}registrarejecutivo`, data);
    // return this.http.post(url + 'registrausuario', data);
  }

  catalogoPreguntas() {
    return this.http.get(url + 'preguntassecretas');
  }
  tipoCuenta(usuario: string) {
    return this.http.get(url + 'tipoCuenta/'+ `${usuario}`);
  }
  validaSesion(usuario: string): Observable<any> {
    return this.http.post(url + 'validaSesion', `{ 
      "Usuario" : "${usuario}" }`);
  }

  actualizaCreaSesion(Usuario: string, Status: number = 1): Observable<any> {
    let req = {
      Usuario,
      Status
    }
    return this.http.post(url + 'actualizaSesion', req);
  }

  restablecerPassword(request) : Observable<IResponseRestablecerPassword> {
    return this.http.post<IResponseRestablecerPassword>(url + 'restablecerPassword', request);
  }

  validaUsuario(usuario:string): Observable<any>{
    return this.http.post(url + 'VerificaRegistroUsuario', `{ "Usuario" : "${usuario}" }`);
  }

  obtenerPreguntasSecretas(request: any): Observable<any> {
    return this.http.post(url + "recuperaPreguntasSecretas", request);
  }

  desencriptaData(request: any): Observable<any> {
    return this.http.post(url + "desencriptaData", request);
  }

  validarPregunta(request: any): Observable<any> {
    return this.http.post(url + "validarPreguntaSeguridad", request);
  }

  obtenerTipoCuenta(request: any): Observable<any> {
    return this.http.post(url + 'tipoCuenta/', request);
  }
}
