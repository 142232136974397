import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ModalImagenAyudaPolizaComponent } from 'src/app/pages/clientes/@components/modal-imagen-ayuda-poliza/modal-imagen-ayuda-poliza.component';
import { MatDialog } from '@angular/material/dialog';
import { AdministrarUsuariosService } from 'src/app/services/administracion-usuarios/administrar-usuarios.service';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IResponseValidaContrasena, Validacion } from 'src/app/models/administracion-usuarios/administracion-usuarios.model';
import ValidationRegistro from '../validators/registro.validators';

@Component({
  selector: 'app-snackbar-errores-password',
  template: `
    <div class="text-small" [innerHTML]="data.html"></div>
  `,
  styles: ['.text-small {font-size : 12px !important}']
})
export class SnackbarMsmComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBar) {
  }
}

export interface Pregunta {
    value: string;
    viewValue: string;
  }

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-registro-asegurado-component',
  templateUrl: './registro-asegurado.component.html',
  styleUrls: ['./registro-asegurado.component.scss']
})

export class RegistroAseguradoComponent implements OnInit {
  activarBtn = false;
  imagen: string;
  loading = false;
  hide = true;
  formularioAse: FormGroup;
  subsFormPassword$: Subscription;
  generaErrorPasswordForm = false;
  validandoPassword = false;
  // variable para catalogo de preguntas secretas
  catPreguntas: [] = [];

  @Output() evento = new EventEmitter();
  errores: any = '';
  subsFormLinea = new Subscription();
  reglas = [
    { Linea: 1, Inciso: true, Ubicacion: false, Certificado: false, Seccion: false },
    { Linea: 2, Inciso: false, Ubicacion: true, Certificado: false, Seccion: false },
    { Linea: 4, Inciso: false, Ubicacion: false, Certificado: true, Seccion: false },
    { Linea: 3, Inciso: false, Ubicacion: false, Certificado: true, Seccion: true },
    { Linea: 7, Inciso: false, Ubicacion: false, Certificado: true, Seccion: true },
    { Linea: 8, Inciso: false, Ubicacion: false, Certificado: true, Seccion: true }
  ]
  imagenTooltip: string = 'assets/img/ejemplos-poliza/ColectivoAccidentesPersonales.jpg';
  linea = '';
  producto = '';
  constructor(private authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private adminService: AdministrarUsuariosService) { }

  ngOnInit() {
    this.imagenAleatoria();
    this.instaciarFiltros();
    this.catalogoPreguntas();
    this.subsFormLinea = this.formularioAse.get('Linea').valueChanges.pipe(debounceTime(500)).subscribe(ln => {
      console.log(ln);
      if (ln === '1' || ln === '2' || ln === '4' || ln === '3' || ln === '7' || ln === '8') {
        console.log(ln);
        const linea = this.reglas.find(item => item.Linea === parseInt(ln, 10));
        this.formularioAse.get('Inciso')[linea.Inciso ? 'enable' : 'disable']();
        this.formularioAse.get('Ubicacion')[linea.Ubicacion ? 'enable' : 'disable']();
        this.formularioAse.get('Certificado')[linea.Certificado ? 'enable' : 'disable']();
        this.formularioAse.get('Seccion')[linea.Seccion ? 'enable' : 'disable']();
        linea.Inciso ? null : this.formularioAse.get('Inciso').reset(0);
        linea.Ubicacion ? null : this.formularioAse.get('Ubicacion').reset(0);
        linea.Certificado ? null : this.formularioAse.get('Certificado').reset(0);
        linea.Seccion ? null : this.formularioAse.get('Seccion').reset(0);
        this.activarBtn = true;
      } else {
        this.activarBtn = false;
      }
      this.linea = ln;
      this.tipoImagenTooltip(this.linea, this.producto);
    });
    this.formularioAse.get('Producto').valueChanges.pipe(debounceTime(500)).subscribe(pr =>{ 
      this.producto = pr;
      this.tipoImagenTooltip(this.linea, this.producto);
    })
    this.subsFormPassword$ = this.formularioAse.get('Password').valueChanges.pipe(debounceTime(1500), distinctUntilChanged()).subscribe((pass: string) => {
      if (pass.length > 0) this.validarContrasena(pass);
    });
  }

  // Carga el catalogo de preguntas secretas
  catalogoPreguntas() {
    this.authService.catalogoPreguntas().subscribe(resp => {
      this.catPreguntas = Object.assign(resp);
    })
  }

  registrar() {
    console.log(this.formularioAse.value);
    if(this.generaErrorPasswordForm) return this.abrirSnackBar('La contraseña de tener un formato correcto', `Contraseña`, 'exitoSnackbar');
    this.authService.registroUsuario(this.formularioAse.getRawValue()).subscribe(resp => {
      this.errores = resp['claveUsuario']
      Swal.fire({
        icon: 'success',
        title: 'Usuario registrado',
        text: '',
        confirmButtonText: 'Aceptar',
      });
      this.regresar();
    }, err => {
      this.errores = '';
      if (err?.error?.Message) this.errores = err.error.Message;
      if(err?.error && Array.isArray(err?.error)) {
        err.error.forEach(element => {
          this.errores = `${this.errores}${element.Exception} <br>`
        });
      }
      Swal.fire({
        icon: 'error',
        title: 'Error al registrar',
        text: '' + this.errores,
        confirmButtonText: 'Aceptar',
      });
    });
    
  }

  instaciarFiltros() {
    this.formularioAse = new FormGroup({
      Sucursal: new FormControl(null, Validators.required),
      Linea: new FormControl(null, Validators.required),
      Producto: new FormControl(null, Validators.required),
      Poliza: new FormControl(null, Validators.required),

      Seccion: new FormControl({ value: 0, disabled: true }, Validators.required),
      Inciso: new FormControl({ value: 0, disabled: true }, Validators.required),
      Ubicacion: new FormControl({ value: 0, disabled: true }, Validators.required),
      Certificado: new FormControl({ value: 0, disabled: true }, Validators.required),

      Dependiente: new FormControl(0, Validators.required),
      AnioEmision: new FormControl(0, Validators.required),
      Reexpedicion: new FormControl(0, Validators.required),

      Password: new FormControl("", [Validators.required]),
      ConfirmaPassword: new FormControl("", [Validators.required]),
      VerificarE: new FormControl(0),
      Telefono: new FormControl(null, Validators.required),
      Alias: new FormControl('', Validators.required),
      IdPregunta: new FormControl('', Validators.required),
      Respuesta: new FormControl('', Validators.required),

      Email: new FormControl("", [Validators.required, Validators.email]),
      FechaInicioVigencia: new FormControl(null, Validators.required),
    }, {
      validators : ValidationRegistro.match('Password', 'ConfirmaPassword')
    });
  }

  regresar() {
    this.evento.emit(true);
  }

  imagenAleatoria() {
    this.imagen = `assets/img/${Math.floor((Math.random() * Math.floor(5)) + 1)}.jpg`;
    setTimeout(() => {
      this.imagenAleatoria();
    }, 30000);
  }
  openModalImagenAyuda() {
    this.dialog.open(ModalImagenAyudaPolizaComponent, {
      data: {
        urlImage: this.imagenTooltip
      }
    })
  }

  validarContrasena(password: string) {
    let msgError = '';
    this.validandoPassword = true;
    const request = { password, usuario: "" };
    this.adminService.postValidaContrasena(request).subscribe((resp: IResponseValidaContrasena) => {
      this.validandoPassword = false;
      if (resp?.datos.length > 0) {
        this.generaErrorPasswordForm = true;
        this.procesarErroresPassword(resp.datos);
        document.getElementById('iconPass').style.color = '#8395a7';
      } else {
        this.generaErrorPasswordForm = false;
        document.getElementById('iconPass').style.color = '#10ac84';
      }
    }, error => {
      this.validandoPassword = false;
      this.generaErrorPasswordForm = false;
      const err = error.error || [];
      if (!err.length) msgError = "Ups, no se pudo comprobar el formato de la contraseña";
      err.forEach((element, index) => {
        if (element.Visible) msgError = index === 0 ? element.Exception : `${msgError} ${element.Exception}`;
        if (element.password) msgError = element.password[0];
      });
      document.getElementById('iconPass').style.color = '#8395a7';
      this.abrirSnackBar(msgError, `Contraseña`, 'errorSnackbar');
    });
  }

  procesarErroresPassword(array: Validacion[]) {
    let msgError = "";
    array.forEach((element) => {
      if (array.length === 1)
        msgError += `<li> ${element.Descripcion} </li>`;
      else
        msgError += `<li> ${element.Descripcion} </li> <br>`;
    });
    this.abrirSnackBar(msgError, `Contraseña`, 'exitoSnackbar');
  }

  abrirSnackBar(mensaje: string, titulo: string, clase: string) {
    this.snackBar.openFromComponent(SnackbarMsmComponent, {
      duration: 5000,
      panelClass: clase,
      data: {
        html: mensaje
      }
    })
  }

  tipoImagenTooltip(ln, pr) {
    // Images
    if (ln == 1  && pr == 24) return this.imagenTooltip = 'assets/img/ejemplos-poliza/FlotillaAutos.jpg'; 
    if (ln == 3  && pr == 3 ) return this.imagenTooltip = 'assets/img/ejemplos-poliza/GrupoGastosMedicosMayores.jpg';
    if (ln == 8  && pr == 11) return this.imagenTooltip = 'assets/img/ejemplos-poliza/ColectivoAccidentesPersonales.jpg';  
    if (ln == 1) return this.imagenTooltip = 'assets/img/ejemplos-poliza/IndividualAutos.jpg';
    if (ln == 2) return this.imagenTooltip = 'assets/img/ejemplos-poliza/IndividualDanos.jpg';
    if (ln == 3) return this.imagenTooltip = 'assets/img/ejemplos-poliza/IndividualGastosMedicosMayores.jpg';
    if (ln == 4) return this.imagenTooltip = 'assets/img/ejemplos-poliza/IndividualVidaIndividual.jpg';
    if (ln == 7) return this.imagenTooltip = 'assets/img/ejemplos-poliza/GrupoVidaGrupo.jpg';
    if (ln == 8) return this.imagenTooltip = 'assets/img/ejemplos-poliza/IndividualAccidentesPersonales.jpg';
  }
}