<mat-dialog-content [class]="data?.tipo">
    <div class="row">
        <div class="col-md-12 text-right">
            <ng-container [ngSwitch]="data?.tipo">
                <svg *ngSwitchCase="'mat-dialog-content-sucess-alert'" class="hand-cursor" (click)="dialogRef.close(data?.externo ? {cerrar : true} : null)" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#FFFFFF"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_LG"> <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                <svg *ngSwitchCase="'mat-dialog-content-error-alert'" class="hand-cursor" (click)="dialogRef.close(data?.externo ? {cerrar : true} : null)" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#FFFFFF"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_LG"> <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                <svg *ngSwitchDefault class="hand-cursor" (click)="dialogRef.close(data?.externo ? {cerrar : true} : null)" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#7d6955"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_LG"> <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="#7d6955" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
            
            </ng-container>
        </div>
        <div class="col-md-12 text-left">
            <ng-container [ngSwitch]="data?.tipo">
                <h5 *ngSwitchCase="'mat-dialog-content-sucess-alert'">
                    Éxito!
                </h5>
                <h5 class="txt-amarillo" *ngSwitchCase="'mat-dialog-content-info-alert'">
                    <i class="far fa-bell txt-brown"></i>&nbsp; Notificación!
                </h5>
                <h5 *ngSwitchCase="'mat-dialog-content-error-alert'">
                    Error!
                </h5>
                <h5 *ngSwitchDefault>
                    <i class="far fa-bell txt-brown"></i>&nbsp; Notificación!
                </h5>
            </ng-container>
        </div>
        <div class="col-md-12">
            <div class="text-small" [innerHTML]="data.html"></div>
        </div>
    </div>
</mat-dialog-content>