import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//Template
import { ThemeModule } from './@theme/theme.module';
//NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './@store/app.reducers';
import { EffectsModule } from '@ngrx/effects';
import { effectsArr } from './@store/effects';
import { environment } from '../environments/environment';
import { HttpConfigInterceptor } from './services/httpconfig.interceptor';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/account/login/login.component';
import { LogoutComponent } from './pages/account/logout/logout.component';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { GenericTableAppModule } from './@generic-table-app/generic-table-app.module';
import { ModalSesionExpiradaComponent } from './pages/account/modal-sesion-expirada/modal-sesion-expirada.component';
import { ModalIngresarComponent } from './pages/account/modal-ingresar/modal-ingresar.component';
import { RegistroAgenteComponent } from './pages/account/login/registro-agente/registro-agente.component';
import { RegistroAseguradoComponent } from './pages/account/login/registro-asegurado/registro-asegurado.component';

import { ModalConsultaRfcComponent } from './pages/account/modal-consulta-rfc/modal-consulta-rfc.component';
import { ActualizaDatosComponent } from './pages/account/actualiza-datos/actualiza-datos.component';
import { RegistroProveedorComponent } from './pages/account/login/registro-proveedor/registro-proveedor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
//Firebase
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { PagosModule } from './pages/@components/pagos/pagos.module';

import { DesbloqueaUsuarioComponent } from './pages/account/desbloquea-usuario/desbloquea-usuario.component';

import { CookieService } from 'ngx-cookie-service';
import { RestablecerContrasenaComponent } from './pages/account/restablecer-contrasena/restablecer-contrasena.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { RegistroReaseguradorComponent } from './pages/account/login/registro-reasegurador/registro-reasegurador.component';
import { RegistroPromotorComponent } from './pages/account/login/registro-promotor/registro-promotor.component';
import { RegistroEmpleadoComponent } from './pages/account/login/registro-empleado/registro-empleado.component';
import { RegistroEjecutivoComponent } from './pages/account/login/registro-ejecutivo/registro-ejecutivo.component';


// export const DATEFORMAT = {
//   parse: {
//     dateInput: 'LL',
//   },
//   display: {
//     dateInput: 'YYYY-MM-DD',
//     monthYearLabel: 'YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'YYYY',
//   },
// };

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        ModalSesionExpiradaComponent,
        ModalIngresarComponent,
        RegistroAgenteComponent,
        RegistroAseguradoComponent,
        RegistroReaseguradorComponent,
        RegistroEmpleadoComponent,
        RegistroEjecutivoComponent,
        RegistroPromotorComponent,
        ActualizaDatosComponent,
        RegistroProveedorComponent,
        ModalConsultaRfcComponent,
        DesbloqueaUsuarioComponent,
        RestablecerContrasenaComponent
    ],
    imports: [
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ThemeModule.forRoot(),
        GenericTableAppModule,
        PagosModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
        //NGRX
        StoreModule.forRoot(appReducers),
        EffectsModule.forRoot(effectsArr),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production
        }),
        //FIREBASE
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideStorage(() => getStorage()),
        provideMessaging(() => getMessaging()),
        PagosModule
    ],
    providers: [
        CookieService,
        { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'} }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
